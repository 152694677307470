<template>
  <div class="memorial-profile-creation-page">
    <v-breadcrumbs :items="['Profil', 'Edycja profilu pamięci']"></v-breadcrumbs>

    <h1 class="steps-header">Edycja profilu pamięci</h1>

    <MemProfCreationStep1
        v-if="currentStep === 1"
        @update:is-form-valid="setIsFormValid"
    ></MemProfCreationStep1>
    <MemProfCreationStep2
        v-if="currentStep === 2"
        @update:is-form-valid="setIsFormValid"
    ></MemProfCreationStep2>
    <MemProfCreationStep3
        v-if="currentStep === 3"
    ></MemProfCreationStep3>

    <div class="steps-footer">
      <div class="step-button-container">
        <div class="step-button-wrapper">
          <v-btn v-if="currentStep !== 1" class="step-button outline" @click="goToPreviousStep">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="hide-on-mobile">Poprzedni krok</span>
          </v-btn>
        </div>

        <v-btn
            v-if="currentStep !== 3"
            :disabled="isNextButtonDisabled"
            class="step-button"
            @click="goToNextStep"
        >
          <span class="button-label">Zapisz i przejdź dalej</span>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>

        <v-btn
            v-if="currentStep === 3"
            class="step-button"
            @click="saveAndGoToPreview"
        >
          <span class="button-label">Zapisz i przejdź do profilu</span>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import MemProfCreationStep1 from './MemProfCreationStep1.vue';
import MemProfCreationStep2 from './MemProfCreationStep2.vue';
import MemProfCreationStep3 from './MemProfCreationStep3.vue';
import { useMemorialProfileStore } from '@/stores/memorial-profile';
import { mapActions, mapState } from "pinia";
import {useAuthStore} from "@/stores/auth";
import axiosInstance from "@/interceptors/axios.config";

export default {
  components: {
    MemProfCreationStep1,
    MemProfCreationStep2,
    MemProfCreationStep3,
  },
  beforeRouteLeave(to, from, next) {
    this.save();
    next();
  },
  data() {
    const authStore = useAuthStore();
    const memorialProfileStore = useMemorialProfileStore();

    return {
      isFormValid: true,
      isNextButtonDisabled: false,
      apiUrl: process.env.VUE_APP_API_URL,
      currentStep: 1,
      authStore,
      memorialProfileStore,
    };
  },
  computed: {
    ...mapState(useMemorialProfileStore, ['memorialProfile'])
  },
  mounted() {
    document.addEventListener('keyup', this.handleKeyUp);
    this.initProfileData();
  },
  unmounted() {
    document.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    ...mapActions(useMemorialProfileStore, [
      'updateProfileState',
      'addSectionState',
      'removeSectionState',
      'addSectionItemState',
      'removeSectionItemState',
      'updateSectionItemState',
      'resetProfileState',
      'commitTempProfileToStore',
      'createProfile',
      'closeAllSections',
    ]),
    initProfileData() {
      this.slotNumber = this.$route.params.slotNumber;

      this.resetProfileState();

      if (this.slotNumber) {
        this.fetchProfileData();
      } else {
        this.updateProfileState({ slot_number: '' });
      }
    },
    handleKeyUp(event) {
      if (event.key === 'Enter') {
        if (this.currentStep !== 3) {
          this.goToNextStep();
        }
      }
    },
    setIsFormValid(value) {
      this.isFormValid = value;
      this.setIsNextButtonDisabled(value);
    },
    fetchProfileData() {
      if (!this.slotNumber) {
        return;
      }

      axiosInstance.get(`${this.apiUrl}mem-profile/${this.slotNumber}/edit`).then(({data}) => {
        if (!data || this.authStore.currentUserId !== data.owner) {
          this.$router.push({ name: 'home' });
        } else {
          this.updateProfileState(data);
        }
      }).catch(() => {
        this.$router.push({ name: 'home' });
      });
    },
    setIsNextButtonDisabled(isFormValid) {
      this.isNextButtonDisabled = !isFormValid;
    },
    goToPreviousStep() {
      if (this.currentStep === 1) {
        return;
      }

      this.currentStep = this.currentStep - 1;
      this.setIsNextButtonDisabled(this.isFormValid);
    },
    goToNextStep() {
      if (!this.isFormValid) {
        return;
      }

      this.commitTempProfileToStore();
      this.currentStep = this.currentStep + 1;
      this.setIsNextButtonDisabled(this.isFormValid);
      this.save();
    },
    saveAndGoToPreview() {
      this.save(true);
    },
    save(goToPreview = false) {
      try {
        if (this.slotNumber) {
          this.updateProfile(goToPreview);
        } else {
          this.createProfile(goToPreview);
        }
      } catch (error) {
        console.error('Wystąpił błąd:', error);
        this.errorMessage = 'Wystapił błąd. Spróbuj ponownie.';
      }
    },
    async createProfile(goToPreview) {
      const response = await this.createProfile();

      if (!response) {
        return;
      }

      this.goToMemorialProfileView(response.data.slot_number, goToPreview);
      this.closeAllSections();
    },
    async updateProfile(goToPreview) {
      const response = await this.memorialProfileStore.updateProfile();

      if (!response) {
        return;
      }

      this.goToMemorialProfileView(response.data.slot_number, goToPreview);
      this.closeAllSections();
    },
    goToMemorialProfileView(slotNumber, goToPreview) {
      if (slotNumber && goToPreview) {
        this.$router.push({name: 'memorial-profile-view', params: {slotNumber}});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-on-mobile {
  display: none;

  @media (min-width: 767px) {
    display: block;
  }
}

.v-breadcrumbs {
  padding: 8px 0 !important;
  color: #075985 !important;

  .v-breadcrumbs-item {
    padding: 0;
    font-size: 0.875rem;
    font-weight: 400 !important;
    line-height: 150%;
    letter-spacing: 0.1px;
  }

  .v-breadcrumbs-item--disabled {
    font-weight: 600 !important;
    color: #075985 !important;
    opacity: 1;
  }

  .v-breadcrumbs-divider {
    padding: 0 4px !important;
  }
}

.memorial-profile-creation-page {
  width: 100%;
  margin: 32px auto;
  padding: 20px;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 600px;
  }
}

.steps-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding-bottom: 5px;

  @media (max-width: 767px) {
    height: 48px;
    background-color: transparent;
    border-top: none;

    .step-button-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .v-btn__content {
      justify-content: center;
    }
  }
}
</style>
